import React from 'react'
import {
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useColorModeValue,
} from '@chakra-ui/react'
import { Link } from 'gatsby'
import { format } from 'date-fns'

interface Props {
  events: any[]
}

const NearbyEventsTable: React.FC<Props> = ({ events }) => {
  const lightTextColor = useColorModeValue('gray.500', 'gray.400')

  return (
    <Box overflow="auto">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Event</Th>
            <Th>Location</Th>
            <Th isNumeric>Date</Th>
          </Tr>
        </Thead>
        <Tbody>
          {events.map(event => {
            return (
              <Tr key={event.id} fontSize="sm">
                <Td>
                  <Link to={`/events/${event.slug_name}`}>
                    <Text
                      fontWeight="semibold"
                      _hover={{ textDecor: 'underline' }}
                    >
                      {event.name}
                    </Text>
                  </Link>
                </Td>
                <Td>{event.location}</Td>
                <Td isNumeric>
                  <Text fontWeight="semibold">
                    {format(new Date(event.start_timestamp * 1000), 'h:mm aaa')}
                  </Text>
                  <Text color={lightTextColor}>
                    {format(
                      new Date(event.start_timestamp * 1000),
                      'MMM dd yyyy'
                    )}
                  </Text>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Box>
  )
}

export default NearbyEventsTable
