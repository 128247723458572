import React from 'react'
import { Text } from '@chakra-ui/react'

interface Props {
  count: number
}

const OrdersCount: React.FC<Props> = ({ count }) => {
  return (
    <Text fontSize="xl" fontWeight="bold">
      {count.toLocaleString()}
    </Text>
  )
}

export default OrdersCount
