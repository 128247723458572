import React from 'react'
import { Box, Text, useColorModeValue } from '@chakra-ui/react'
import { Bar, XAxis, BarChart, Tooltip, ResponsiveContainer } from 'recharts'

interface Props {
  totalCount: number
  pendingCount: number
  fulfilledCount: number
  canceledCount: number
}

const OrdersChart: React.FC<Props> = ({
  totalCount,
  pendingCount,
  fulfilledCount,
  canceledCount,
}) => {
  const tooltipBgColor = useColorModeValue('gray.50', 'gray.700')

  const chartData = [
    { name: 'Total', amount: totalCount },
    { name: 'Pending', amount: pendingCount },
    { name: 'Fulfilled', amount: fulfilledCount },
    { name: 'Canceled', amount: canceledCount },
  ]

  const renderTooltip = (props: any) => {
    const name = props.payload[0]?.payload?.name
    const value = props.payload[0]?.value

    return (
      <Box bgColor={tooltipBgColor} px={3} py={2} rounded="lg" minW="80px">
        <Text fontSize="sm">{name}</Text>
        <Text fontWeight="bold">{value}</Text>
      </Box>
    )
  }

  return (
    <Box w="100%" h="100%" minH="150px">
      {totalCount > 0 && (
        <ResponsiveContainer width="100%" minHeight="100%" maxHeight={150}>
          <BarChart
            barSize={24}
            data={chartData}
            margin={{ top: 16, right: 0, bottom: 24, left: 0 }}
          >
            <Tooltip content={renderTooltip} cursor={{ fill: 'transparent' }} />
            <XAxis
              dataKey="name"
              tickLine={false}
              tick={{ fontSize: '13px', fontWeight: 600, opacity: 1 }}
              axisLine={{ strokeOpacity: 0.35 }}
            />
            <Bar
              dataKey="amount"
              fill="#fff"
              // fill="#f92068"
              style={{ backgroundColor: 'transparent' }}
            />
          </BarChart>
        </ResponsiveContainer>
      )}
      {totalCount === 0 && (
        <Text color="white" fontWeight="semibold">
          No orders
        </Text>
      )}
    </Box>
  )
}

export default OrdersChart
