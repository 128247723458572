import React from 'react'
import { Progress } from '@chakra-ui/progress'

import ContentBox from '~components/shared/content-box'
import ContentBoxTitle from '~components/shared/content-box-title'
import TopEventsTable, { ITopEvent } from './top-events-table'

interface Props {
  isLoading: boolean
  topEvents: ITopEvent[]
}

const TopEventsBox: React.FC<Props> = ({ isLoading, topEvents }) => {
  return (
    <ContentBox p={0} py={6}>
      <ContentBoxTitle pl={6}>Top Events</ContentBoxTitle>
      <TopEventsTable topEvents={topEvents} />
      {isLoading && <Progress h="2px" colorScheme="brand" isIndeterminate />}
    </ContentBox>
  )
}

export default TopEventsBox
