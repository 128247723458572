import {
  startOfDay,
  startOfWeek,
  startOfMonth,
  startOfYear,
  endOfDay,
  endOfWeek,
  endOfMonth,
  endOfYear,
  isSameDay,
  isBefore,
  format,
  sub,
} from 'date-fns'

export enum FilterRange {
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  THIS_WEEK = 'This week',
  LAST_WEEK = 'Last week',
  THIS_MONTH = 'This month',
  LAST_MONTH = 'Last month',
  THIS_YEAR = 'This year',
  LAST_YEAR = 'Last year',
  //   ALL_TIME = 'All time',
}

export function getDatesRange(filterRange: FilterRange) {
  const now = new Date()

  switch (filterRange) {
    case FilterRange.TODAY:
      return [startOfDay(now), endOfDay(now)]
    case FilterRange.YESTERDAY:
      return [
        startOfDay(sub(now, { days: 1 })),
        endOfDay(sub(now, { days: 1 })),
      ]
    case FilterRange.THIS_WEEK:
      return [
        startOfWeek(now, { weekStartsOn: 1 }),
        endOfWeek(now, { weekStartsOn: 1 }),
      ]
    case FilterRange.LAST_WEEK:
      return [
        startOfWeek(sub(now, { weeks: 1 }), { weekStartsOn: 1 }),
        endOfWeek(sub(now, { weeks: 1 }), { weekStartsOn: 1 }),
      ]
    case FilterRange.THIS_MONTH:
      return [startOfMonth(now), endOfMonth(now)]
    case FilterRange.LAST_MONTH:
      return [
        startOfMonth(sub(now, { months: 1 })),
        endOfMonth(sub(now, { months: 1 })),
      ]
    case FilterRange.THIS_YEAR:
      return [startOfYear(now), endOfYear(now)]
    case FilterRange.LAST_YEAR:
      return [
        startOfYear(sub(now, { years: 1 })),
        endOfYear(sub(now, { years: 1 })),
      ]
    default:
      return [startOfDay(now), endOfDay(now)]
  }
}

export function getRangeDisplay(startDate: Date, endDate: Date) {
  const isPastYear =
    isBefore(startDate, startOfYear(new Date())) ||
    isBefore(endDate, startOfYear(new Date()))
  const formatStr = isPastYear ? 'MMM do yyyy' : 'MMM do'

  if (isSameDay(startDate, endDate)) return format(startDate, formatStr)
  return `${format(startDate, formatStr)} - ${format(endDate, formatStr)}`
}
