import React, { useState, useEffect } from 'react'
import { Progress } from '@chakra-ui/progress'

import ContentBox from '~components/shared/content-box'
import ContentBoxTitle from '~components/shared/content-box-title'
import NearbyEventsTable from './nearby-events-table'
import { eventsIndex } from '~utils/algolia'

const NearbyEventsBox: React.FC = () => {
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(true)

  const searchEvents = async () => {
    setLoading(true)
    const { hits } = await eventsIndex.search('', {
      hitsPerPage: 10,
      aroundLatLngViaIP: true,
      facetFilters: ['isActive: true'],
      filters: `start_timestamp > ${Math.floor(new Date().getTime() / 1000)}`,
    })
    // @ts-ignore
    setEvents(hits)
    setLoading(false)
  }

  useEffect(() => {
    searchEvents()
  }, [])

  return (
    <ContentBox p={0} py={6}>
      <ContentBoxTitle pl={6}>Events near you</ContentBoxTitle>
      <NearbyEventsTable events={events} />
      {loading && <Progress h="2px" colorScheme="brand" isIndeterminate />}
    </ContentBox>
  )
}

export default NearbyEventsBox
