import React from 'react'
import { Box, Text, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import { Link } from 'gatsby'

export interface ITopEvent {
  id: string
  orders: number
  eventName: string
  eventSlug: string
}

interface Props {
  topEvents: ITopEvent[]
}

const TopEventsTable: React.FC<Props> = ({ topEvents }) => {
  return (
    <Box overflow="auto">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Event</Th>
            <Th isNumeric>Orders</Th>
          </Tr>
        </Thead>
        <Tbody>
          {topEvents.map((event, i) => (
            <Tr key={event.id} fontSize="sm">
              <Td>
                <Link to={`/events/${event.eventSlug}`}>
                  <Text
                    fontWeight="semibold"
                    _hover={{ textDecor: 'underline' }}
                  >
                    {i + 1}. {event.eventName}
                  </Text>
                </Link>
              </Td>
              <Td isNumeric>{event.orders}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  )
}

export default TopEventsTable
